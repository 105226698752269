<template>
  <!-- Ao alterar o max-width é necessário alterar o border-left e border-right no css -->
  <v-dialog
    scrollable
    :max-width="maxWidth"
    :value="value"
    @click:outside="$emit('close')"
  >
    <validation-observer v-slot="{ invalid }">
      <v-card class="pa-8 d-flex flex-column" id="envelope">
        <v-card-title>
          <slot name="title" class="text-center" />
        </v-card-title>
        <v-card-subtitle>
          <slot name="subtitle" />
        </v-card-subtitle>
        <v-card-text style="max-height: 500px; overflow-y: auto;">
          <slot name="text" />
        </v-card-text>
        <v-card-actions
          class="d-flex justify-space-between align-end flex-grow-1"
        >
          <slot name="actions">
            <v-btn @click="$emit('cancelar')" plain>{{ cancelarLabel }}</v-btn>
            <v-btn
              color="error"
              @click="$emit('confirmar')"
              :disabled="invalid"
              >{{ confirmarLabel }}</v-btn
            >
          </slot>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConferenciaBaseDialog',
  props: {
    cancelarLabel: {
      type: String,
      default: 'Cancelar',
    },
    confirmarLabel: {
      type: String,
      default: 'Confirmar',
    },
    maxWidth: {
      type: String,
      default: '600px',
    },
    value: {
      type: Boolean,
      required: true,
    },
    envelopado: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.envelopado) {
      const el = document.getElementById('envelope')
      el.setAttribute('id', 'envelopado')
    }
  },
}
</script>

<style>
.v-dialog {
  overflow-y: initial !important;
}

#envelopado {
  position: relative;
}

#envelopado::before {
  --length: 175px;
  content: '';
  position: absolute;
  top: calc(-1 * var(--length));
  left: 0px;
  /* border-left e border-right deve ser 50% do max-width */
  border-left: 600px solid transparent;
  border-right: 600px solid transparent;
  border-bottom: var(--length) solid #f1f1f1;
}
</style>
