<template>
  <v-card>
    <v-card-text>
      <v-card-subtitle class="d-flex justify-space-between">
        <span>Total Serviço</span>
        <span class="entrada green--text"
          ><strong>{{ toReal(totalServico) }}</strong></span
        >
      </v-card-subtitle>
      <v-card-subtitle class="d-flex justify-space-between">
        <span>Total Cancelados</span>
        <span class="retirada red--text"
          ><strong>{{ toReal(totalServicoCancelado) }}</strong></span
        >
      </v-card-subtitle>
      <v-card-subtitle class="d-flex justify-space-between">
        <span>Total em Caixa</span>
        <span class="total-em-caixa" 
          ><strong>{{ toReal(totalServico - totalServicoCancelado) }}</strong></span
        >
      </v-card-subtitle>
      <v-card-subtitle class="d-flex justify-space-between">
        <span>Total de Caminhões</span>
        <span class="primary--text"
          ><strong>{{ totais.total_caminhoes }}</strong></span
        >
      </v-card-subtitle>
    </v-card-text>
  </v-card>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'ConferenciaResumoTotal',
  mixins: [toReal],
  props: {
    totais: {
      type: Object,
      required: true,
    },
    resumoServico: {
      type: Array,
      required: true,
    },
    resumoServicoCancelado: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalServico() {
      return this.resumoServico.reduce((acc, o) => acc + o.total, 0)
    },
    totalServicoCancelado() {
      return this.resumoServicoCancelado.reduce((acc, o) => acc + o.total, 0)
    },
    totalEmCaixaClass() {
      return this.totais.total_em_caixa > 0 ? 'green--text' : 'error--text'
    },
  },
}
</script>

<style lang="scss" scoped>
.retirada::before {
  content: '- ';
}
.entrada::before {
  content: '+ ';
}
.total-em-caixa::before {
  content: '= ';
}
</style>
