import { render, staticRenderFns } from "./ConferenciaResumoTotal.vue?vue&type=template&id=38c4c210&scoped=true&"
import script from "./ConferenciaResumoTotal.vue?vue&type=script&lang=js&"
export * from "./ConferenciaResumoTotal.vue?vue&type=script&lang=js&"
import style0 from "./ConferenciaResumoTotal.vue?vue&type=style&index=0&id=38c4c210&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c4c210",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardSubtitle,VCardText})
